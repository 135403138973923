/** Common里面全局要用到 */
import config from "../../../../config/config";


//全局明亮色调(白底黑字)
const styleGlobalBright = {
  primaryText: "#000", //整个组件中普通的字体颜色
  warningText: "#E50100",
  remarkText: "#C0252D", //#d14738
}

//全局黑暗色调(黑底白字)
const styleGlobalDark = {
  primaryText: "#fff", //整个组件中普通的字体颜色
  warningText: "#E50100",
  remarkText: "#C0252D",
}

export const styleGlobal = {
  "wapT4": styleGlobalDark,//与wap369同一个样式
  "theme369": styleGlobalBright,
  "red": styleGlobalBright,
  "pbDarkModeRed": styleGlobalDark,
  "T20":styleGlobalDark,
}[config.theme] ?? styleGlobalBright;


