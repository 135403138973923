import {getCountryCode} from "../templates";

const validateDepositAndWithdrawAmount = (input, maxAmount, minAmount) => {
  const regex = /^(?!0)[0-9]+$/;
  const isValid = regex.test(input) && Number(input) <= maxAmount && Number(input) >= minAmount;
  return !isValid;
};

function copy(item) {
  try {
    navigator.clipboard.writeText(item);
  } catch (e) {
    console.log(e.message);
  }
}

function formatNumberWithCommas(x) {
  if (typeof x === "number") return x.toString(); //copy from tgs-web-nx
  if (isNaN(x)) return x;
  return parseFloat(x)
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

function formatNumberWithThousand(x) {
  if (isNaN(x)) return x;
  return x.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

function formatNumberWithCommasNoRounding(x) {
  if (isNaN(x)) return x;
  return parseFloat(x)
    .toFixed(6)
    .slice(0, -4)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

/**
 * 取得貨幣格式
 * @param amount 金額
 * @param countryCode 國碼
 * @returns 貨幣格式
 */
const formatCurrency = (amount) => {
  return new Intl.NumberFormat(getCountryCode()).format(amount);
};

/**
 * 格式化金額無條件進位至小數第二位  copy by tgs-web-nx
 */
function roundUpToTwoDecimals(amount) {
  //無條件進位到小數點後兩位
  const rounded = Math.ceil(amount * 100) / 100;
  return formatNumberWithCommas(
    // 如果數字是整數，返回整數部分，否則返回保留兩位小數的字符串
    rounded % 1 === 0 ? rounded.toString() : rounded.toFixed(2).replace(/\.?0+$/, ""),
  );
}


export const CommonUtil = {
  validateDepositAndWithdrawAmount,
  copy,
  formatNumberWithCommas,
  formatNumberWithThousand,
  formatNumberWithCommasNoRounding,
  formatCurrency,
  roundUpToTwoDecimals
};
