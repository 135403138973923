/** 处理品牌相关逻辑
 */
import config from "../../../../config/config";
import IconDepositRedPNG from "../../assets/images/deposit/icon-deposit.png";
import IconDepositRedPNG369 from "../../assets/images/deposit/icon-deposit-369.png";

export * from "./templateBrand";

const brand = config.brand;


export const getCountryCode = () => {
  return {
    /*可配置每个品牌的货币编码*/
  }
    [brand] ?? "en-US";
};

export const getDepositTitleIcon = () => {
  return config.is369 ? IconDepositRedPNG369 : IconDepositRedPNG;
}

/** 获取每个品牌的汇率比较 */
export const getBrandCurrencyRate = () => {
  return {"369VN": 1000}[brand] ?? 1;
}

/**
 * 描述：統一將T1、T20所有站台註冊調整為”註冊時進行電話、簡訊驗證  除了PB和369
 * -T1版型
 * 品牌:pb->電話號碼
 * 品牌:v227->電話+簡訊驗證
 * 品牌:369VN->電話號碼
 * 品牌:369HK->不須電話號碼
 *
 * -T20
 * 品牌:JILI_PH->電話+簡訊驗證
 * 品牌：PITO7->不進行驗證
 * */
export const IsWithSMSCode = config.features.SMSVerificationInRegister || !["PB","369VN", "369_HKD", "PITO7"].includes(brand);

/** 因为手机版都是共用的子模块Wap 所以Wap在判断哪些品牌用新的存款,哪些用旧的存款 组信誓旦旦 */
export const IsUseDepositReactor = ["T1", "T20", "369VN", "369_HKD", "PB", "V227"].includes(brand);

/** 默认推广码类型 p为玩家 a为代理
 * @deprecated
 */
export const defaultPromoteType = "a";
