import { ApiService } from "./ApiService";
import config from "../../config/config";
export const AccountService = {
  CheckUserName,
  Login,
  Register,
  Activation,
  VerifyActivation,
  ActivationEmail,
  VerifyActivationEmail,
  Logout,
  GetMemberInfo,
  AddRealName,
  CheckRealName,
  ChangeWithdrawalPassword,
  AddWithdrawalPassword,
  GetChangeWithdrawalPasswordVerificationCode,
  VerifyEmail,
  UpdateEmail,
  VerifyPhone,
  UpdatePhone,
  UpdatePassword,
  VerifyForgotPassword,
  ResetPassword,
  GetIDCardNo,
  GetPromoteUser,
  GetPromoteBill,
  UpdateMemberName,
  UploadIC,
  SetBirthDate,
  NewRegister,
  UpdateIM,
  GetRegisterStatus,
  UpdateMemberNameAndIC,
  GetBucketTypeAmount,
};

function CheckUserName(username) {
  const result = ApiService.get(`Player/checkUsername?=${username}`);
  return result;
}

function Login(username, password) {
  const result = ApiService.post("Player/Login", {
    username: username,
    password: password,
    platformtype: config.isPC,
  });
  result.then((response) => {});
  return result;
}

function Register(username, pwd, confirmpwd, invitationcode = "", upper) {
  var currency = config.currency;
  const result = ApiService.post("Player/create", {
    username,
    currency,
    pwd,
    confirmpwd,
    invitationcode,
    agent: true,
    testplayer: config.testplayer,
    upper,
  });
  return result;
}
function Activation(username, pwd, areacode, phone) {
  const result = ApiService.post("player/init-phone", {
    username,
    pwd,
    areacode,
    phone,
  });
  return result;
}
function VerifyActivation(securitykey, validatecode) {
  const result = ApiService.put("player/init-phone", {
    securitykey,
    validatecode,
  });
  return result;
}
function ActivationEmail(email) {
  const result = ApiService.post("player/init-email", {
    email,
  });
  return result;
}
function VerifyActivationEmail(securitykey, validatecode) {
  const result = ApiService.put("player/init-email", {
    securitykey,
    validatecode,
  });
  return result;
}
function Logout() {
  const result = ApiService.del("Player/logout", {});
  result.then((response) => {
    if (response.IsSuccess) {
      localStorage.removeItem("token");
    }
  });

  return result;
}

function GetMemberInfo() {
  const result = ApiService.get("Player/info");
  return result;
}

function SetBirthDate(yyyymmdd) {
  const result = ApiService.patch("player/birthdate", {
    birthdate: yyyymmdd,
  });

  return result;
}

function UpdateIM(value, im, param) {
  const result = ApiService.patch(`Player/${im}`, {
    [param]: value,
  });
  return result;
}

function ChangeWithdrawalPassword(newpwd, validateCode, securityKey) {
  const result = ApiService.patch("pay/password", {
    newpwd: newpwd,
    validatecode: validateCode,
    securitykey: securityKey,
  });
  return result;
}

function AddRealName(realname, id) {
  const result = ApiService.patch("player/identity", {
    realname: realname,
    id: id,
  });
  return result;
}

function CheckRealName(id) {
  const result = ApiService.get(`Player/identity/check/${id}`);
  return result;
}

function AddWithdrawalPassword(password) {
  const result = ApiService.post("pay/password", {
    pwd: password,
  });
  return result;
}

function GetChangeWithdrawalPasswordVerificationCode() {
  const result = ApiService.post("pay/password/validate-code", {});

  return result;
}

function VerifyEmail(email) {
  const result = ApiService.post("player/email", {
    email: email,
  });
  return result;
}

function UpdateEmail(securitykey, validateCode) {
  const result = ApiService.put("player/email", {
    securitykey: securitykey,
    validatecode: validateCode,
  });
  return result;
}

function VerifyPhone(phone, areacode) {
  const result = ApiService.post("player/phone", {
    phone: phone,
    area: areacode,
  });
  return result;
}

function UpdatePhone(securitykey, validateCode) {
  const result = ApiService.put("player/phone", {
    securitykey: securitykey,
    validatecode: validateCode,
  });
  return result;
}

function UpdatePassword(oldpwd, newpwd) {
  const result = ApiService.put("player/password", {
    oldpwd: oldpwd,
    newpwd: newpwd,
  });
  return result;
}

function VerifyForgotPassword(email, areaCode, phone) {
  const result = ApiService.post("player/password/forgot", {
    email: email,
    areaCode: areaCode,
    phone: phone,
  });
  return result;
}

function ResetPassword(securitykey, validatecode, newpwd) {
  const result = ApiService.post("player/password/reset", {
    securitykey: securitykey,
    validatecode: validatecode,
    newpwd: newpwd,
  });
  return result;
}
function GetIDCardNo() {
  const result = ApiService.get("pc/authentication");

  return result;
}
function GetPromoteUser(page, limit) {
  const result = ApiService.get(
    `pc/user/promoteUser?page=${page}&page_size=${limit}`
  );

  return result;
}
function GetPromoteBill(page, limit) {
  const result = ApiService.get(
    `pc/userPromoteBill?page=${page}&page_size=${limit}`
  );

  return result;
}
function UpdateMemberName(token, name) {
  const result = ApiService.post(
    "member/editSurename",
    {
      name,
      token,
    },
    true
  );

  return result;
}

function UpdateMemberNameAndIC(realname, id, file) {
  const result = file
    ? ApiService.postFile(
        `player/identity/upload`,
        file,
        {
          realname,
          id,
        },
        true
      )
    : ApiService.patch(`player/identity`, {
        realname,
        id,
      });

  return result;
}

function GetRegisterStatus() {
  const result = ApiService.get("player/register");
  return result;
}

function NewRegister(type, input) {
  const result = ApiService.post("player/register", {
    type: type,
    input: input,
  });
  return result;
}

function UploadIC(file) {
  const result = ApiService.postFile(`player/identity/upload`, file, {});
  return result;
}
function GetBucketTypeAmount() {
  const result = ApiService.get(`player/bucket/type/amount`);
  return result;
}
