import config from "../../../../config/config";
import styled from "styled-components";
import {theme} from "../themes";


const styleTabRed = {
  
  //------存款手机版部分样式-----
  tabText: "#000", //tab选上后的颜色
  tabTextActive: "#fff", //tab没选上的颜色
  
  tabBorder: "#818181",
  tabBorderActive: "#d14738",  //primary或new_primary
  
  tabBackground: "", //没颜色
  tabBackgroundActive: "#d14738", //primary或new_primary
  //------存款手机版部分样式-----
}

const styleTabDarkPc = {
  // --------------- colors in Common submodule ---------------
  
  //------存款手机版部分样式-----
  
  tabText: "#fff", //tab选上后的颜色
  tabTextActive: "#fff", //tab没选上的颜色
  
  tabBorder: "#818181",
  tabBorderActive: "#c40511",  //primary或new_primary
  
  tabBackground: "", //没颜色
  tabBackgroundActive: "#c40511", //primary或new_primary
  // ----------- END colors in Common submodule ---------------
}

const styleTabDarkMobile = {
  // --------------- colors in Common submodule ---------------
  
  //------存款手机版部分样式-----
  
  tabText: "#fff", //tab选上后的颜色
  tabTextActive: "#fff", //tab没选上的颜色
  
  tabBorder: "#818181",
  tabBorderActive: theme.new_primary ?? theme.primary, // "#c40511",  //primary或new_primary
  
  tabBackground: "", //没颜色
  tabBackgroundActive: theme.new_primary ?? theme.primary, //primary或new_primary
  // ----------- END colors in Common submodule ---------------
}

export const styleTab = {
  "wapT4": styleTabDarkMobile,
  "red": styleTabRed,
  "pbDarkModeRed": styleTabDarkPc
}[config.theme] ?? styleTabRed;

