import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { AccountService } from '../../../../../api-services/services/AccountService'
import i18n from "../../../../../utils/i18n";

import { getCustomMsgListResult } from '../../../services'

//TODO:此次hooks僅在適配components的屬性，後續有機會再更改原來的components，再進行優化
export default function() {
	
	const AccountInfo =useSelector(state => state.account.info)
	const [list, setList] = useState([])

	const getCustomMsgList = () => {
		getCustomMsgListResult().then(res => {
			if (res.IsSuccess) {
				const result = []
				res.data.forEach(item => {
					const labelName = item.name?.toLowerCase()
					const label = i18n.t('common.' + (labelName === 'facebookmessenger' ? 'fb' : labelName) )
					const accountName = AccountInfo[labelName]
					result.push({
						name: labelName,
						label,
						attr: labelName === 'facebookmessenger' ? 'member_fb' : 'member_' + labelName,
						path: "/profile/" + (labelName === 'facebookmessenger' ? 'fb' : labelName),
						isVerified: !!accountName,
						accountName,
						showAccountName: accountName ? accountName.substring(0, 1) + "**" : i18n.t("profile.notSet")
					})
				})
				setList(result)
			}
		})
	}

	const updateCustomMsg = (name, value) => {
		AccountService.UpdateIM(value, name, name).then(res => {
			console.log(res)
		})
	}

	useEffect(() => {
		getCustomMsgList()
	}, [])

	return {
		list,
		updateCustomMsg
	}
}