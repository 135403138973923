import config from "../../../../config/config";
const brand = config.brand;


export const isBrandPb = brand==="PB";
export const isBrand369VN = brand==="369VN";

export const isBrand369HK = brand==="369_HKD";

export const isBrandV227 = brand==="V227";

export const isBrandJILI_PH =  brand==="JILI_PH";

export const isBrandPITO7 = brand==="PITO7";